/* eslint-disable @typescript-eslint/naming-convention */

const emojis: { [key: string]: string } = {
  100: "💯",
  question: "❓",
  exclamation: "❗",
  sunrise: "🌅",
  rainbow: "🌈",
  ocean: "🌊",
  volcano: "🌋",
  seedling: "🌱",
  maple_leaf: "🍁",
  wood: "🪵",
  evergreen_tree: "🌲",
  gift: "🎁",
  santa: "🎅",
  tada: "🎉",
  art: "🎨",
  dart: "🎯",
  bug: "🐛",
  wave: "👋",
  ok_hand: "👌",
  building_construction: "🏗️",
  "+1": "👍",
  thumbsup: "👍",
  "-1": "👎",
  thumbsdown: "👎",
  clap: "👏",
  open_hands: "👐",
  ghost: "👻",
  alien: "👽",
  skull: "💀",
  boom: "💥",
  poop: "💩",
  muscle: "💪",
  mage: "🧙‍♀️",
  bow: "🙇‍♂️",
  see_no_evil: "🙈",
  hear_no_evil: "🙉",
  speak_no_evil: "🙊",
  pray: "🙏",
  rocket: "🚀",
  construction: "🚧",
  rotating_light: "🚨",
  no_entry_sign: "🚫",
  clown_face: "🤡",
};

export default emojis;
